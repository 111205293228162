import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  background: #ffffff;
  text-align: center;
  position: relative;

  .p-section-inner {
    max-width: 480px;
    padding: 60px 0;
    margin: 0 auto;
    position: relative;

    .p-section-gallery {
      width: 100%;
      margin: 0 auto 60px;
      padding: 30px 0;
      background: #ffffff;
      position: relative;
      border-bottom: solid 4px #ffc60c;

      &:after {
        display: block;
        position: absolute;
        content: '';
        width: 60px;
        height: 60px;
        background: #ffffff;
        border-bottom: solid 4px #ffc60c;
        border-right: solid 4px #ffc60c;
        transform: rotate(45deg);
        bottom: -32px;
        left: 44%;
      }

      p {
        position: relative;
        z-index: 2;
      }

      img {
        display: block;
        position: absolute;
        width: 40px;
        height: auto;
        top: 0;
        left: -40px;
      }
    }
  }

  .btn-wrap {
    position: relative;
  }

  .p-btn-download {
    width: 470px;

    p {
      margin-right: 100px;
    }
  }

  .img-wrap {
    position: absolute;
    width: 100%;
    height: 120px;
    bottom: 10px;
    right: 3px;
    border-radius: 42.5px;
    overflow: hidden;
    pointer-events: none;

    img {
      height: 84px;
      border: 2px #01b5a6 solid;
      position: absolute;
      bottom: 0px;
      right: 10px;
      transform: rotate(7deg);
    }
  }

  .intro-wrap {
    margin: 30px 0;

    span {
      background: linear-gradient(transparent 50%, #ffff7f 50%);
      padding: 0 5px;
    }
  }
`

/**
 * サービスサイトCTAエリア
 * @constructor
 */
const OfficialDownloadCta = () => {
  const routeParam = useRouteParam('/download')
  return (
    <Wrapper className="anim-fade-up left-in">
      <div className="p-cta-inner anim-fade-up delay-1">
        <div className="p-section-inner">
          <div className="p-section-gallery anim-fade-up">
            <p className="txt-center txt-size-26 txt-bold-green">
              もっとたくさん画面を見てみたい！
            </p>
            <img
              src="/images/official/common/iconSupport01.png"
              alt="キンクラ"
            />
          </div>
          <div className="intro-wrap">
            <p>
              <span>ほぼすべての画面</span>をまとめた資料をご用意しています
            </p>
          </div>
          <div className="btn-wrap">
            <OfficialCommonBtn
              size="mega"
              level="normal"
              className="p-btn-download"
              onClick={() => {
                navigate(routeParam)
              }}
            >
              <p>資料ダウンロード</p>
            </OfficialCommonBtn>
            <div className="img-wrap">
              <img
                src="/images/official/feature/imgDownload03.png"
                alt="キンクラ"
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default OfficialDownloadCta
